<div *ngIf="model">
    <div>
      <nb-toggle *ngIf="!simple && editMode" status="info" [(ngModel)]="htmlMode">HTML editor</nb-toggle>
    </div>
    <div class="flex" [ngClass]="view == 'columns' ? 'flex-row' : 'flex-col'">
      <ng-template ngFor let-lang="$implicit" [ngForOf]="languages" let-i="index">
        
        <div class="flex-1 p-2">
          <div class="lang-label">{{lang}}</div>
          <nb-badge status="danger" class="diff-el" text="Show diff" *ngIf="didChange[lang] && templateModel"
              [nbTooltip]="templateModel[lang]" nbTooltipPlacement="top" [nbTooltipStatus]="danger">
          </nb-badge>
          <div class="text-center" [ngClass]="{'empty-txt': isEmpty(lang)}">
            <nb-select class="mb-1 w-52" placeholder="Add tag" *ngIf="tag">
              <nb-option *ngFor="let tag of tags" (click)="selectTag(tag, lang)" >{{tag}}</nb-option>
            </nb-select>

            <quill-editor [styles]="{height: '500px'}" [(ngModel)]="model[lang]" [ngModelOptions]="{standalone: true}" *ngIf="editMode && htmlMode"></quill-editor>
            <!--<ckeditor [editor]="Editor" *ngIf="htmlMode && editMode" 
              [ngModel]="model[lang] ? model[lang] : ''" (ngModelChange)="model[lang] = $event" [ngModelOptions]="{standalone: true}">
            </ckeditor>-->
            <!-- [config]="ckeConfig"-->
            

            <textarea nbInput fullWidth class="form-control bg-white" rows="12" [(ngModel)]="model[lang]" *ngIf="editMode && !htmlMode && !simple"></textarea>
            <input nbInput type="text"  class="form-control bg-white" [(ngModel)]="model[lang]" *ngIf="editMode && !htmlMode && simple">
            
            <!--<div class="text-left" [innerHtml]="model[lang]" *ngIf="htmlMode && !editMode" ></div>-->
            <quill-view [content]="model[lang]" theme="snow" *ngIf="!editMode && htmlMode"></quill-view>

            <div *ngIf="!editMode && !htmlMode">
              <span [ngClass]="{'changed' : didChange[lang]}">{{model[lang]}}</span>
            </div>
          </div>
          <button [nbSpinner]="loading[i]" nbSpinnerStatus="primary" type="button" *ngIf="editMode && translationButton && !model[lang] && hasTranslation()" 
              class="p-1.5 row btn p-0 btn-success btn-rounded ml-3 mt-3" (click)="translateText(model, lang, i)">
            <nb-icon class="translate-icon" icon="globe-outline" pack="eva"></nb-icon>Translate
          </button>
        </div>
      </ng-template>
      <div class="editor-action">
          <button class="btn p-0 btn-success btn-rounded ml-3 " (click)="editModel()" *ngIf="!editMode && !readonly">
            <nb-icon icon="edit-outline" pack="eva"></nb-icon>
          </button>
          <button nbButton class="btn p-0 btn-save btn-rounded ml-3 " [nbContextMenu]="applyMenu" [nbContextMenuTag]="'multilang-editor'+attribute" *ngIf="!editMode && showApplyChanges">
            <nb-icon icon="code-download-outline" pack="eva"></nb-icon>
          </button>
      </div>
    </div>
    <div class="row w-100 d-flex align-items-center p-2" *ngIf="changeCount>0 && templateModel">
      <div class="col">
        <button type="button" nbButton size="tiny" status="danger" (click)="updateFieldFromTemplate()">Update from template</button>
      </div>
    </div>
</div>