import Serializable from './serializable';

export class Game extends Serializable {
	id: string;

	themeId: string;
	episodeId: string;
	cityId: string;
	//universeEventId: string;
	published: boolean;

	title: {} = {};
	articleText: {} = {};
	highlightPicture: string;
	highlightText: {} = {};
	
	name: {} = {};
	description: {} = {};
	minimumNativeVersion: string;
	objects
	availableLanguages: string[];
	workingLanguages: string[];
	defaultLang: string;

	ttsVoiceId:string;
	languageTtsVoiceId: {} = {};

	locationMode: string; //GEO/VIRTUAL/NONE
	mapTheme: {
		virtualMapUrl: string; //https://coddy-app.firebaseapp.com/assets/map/pirates
		minZoom: number;
		maxZoom: number;
	} = { 
		virtualMapUrl: null,
		minZoom: 0,
		maxZoom: 0 
	};
	hasZone: boolean;
	gameZone: {
		paths: [],
		strokeColor: string,
		strokeOpacity: string,
		strokeWeight: string,
		fillColor: string,
		fillOpacity: string,
	};

	/*paths: [
			{ lat: 48.8, lng: 2.3 },
			{ lat: 48.8, lng: 12.3 },
			{ lat: 58.8, lng: 12.3 },
			{ lat: 58.8, lng: 2.3 },
			{ lat: 48.8, lng: 2.3 }
		  ],
		  strokeColor: '#084C61',
		  strokeOpacity: '1',
		  strokeWeight: '1',
		  fillColor: '#084C61',
		  fillOpacity: '0.2' */
	
	latitude: number;
	longitude: number;
	location: string;
	endLatitude: number;
	endLongitude: number;
	endLocation: string;

	isExternalPartner: boolean;
	alwaysVisible: boolean;
	visibilityRange: number;
	
	timeLimited: boolean;
	timeLimitedMinutes: number;
	timerStartMission: string;
	timerStopMission: string;
	stopWhenOver: boolean;
	timerFallbackMission: string;

	startTime: number;
	duration: number;
	endTime: number;
	timezone: string;

	hasRoles: boolean;
	hasCharacters: boolean;

	creationDate: number;

	pictureUrl: string;
	thumbFileName: string;
	thumbFileURL: string;
	pictureLastUpdate: number;

	documents: {} = {en:{}, fr:{}, nl:{}};
	instructions: {} = {en:"", fr:"", nl:""};
	hasGameOverUrl: boolean;
	gameOverUrl: string;

	averageMissionCount: number;
	missions = [];

	teamCount: number;
	teamCountInitiated: number;
	teamCountInitiatedAndCompleted: number;

	players: {};
	scores: {};

	//local use only
	expired = false;

	lastUpdateDate: number;
	lastUpdateNotes: string;

	getPercentageNotCompleted(){
		if(this.teamCountInitiated > 0){
			return Math.round(((this.teamCountInitiated - this.teamCountInitiatedAndCompleted)/this.teamCountInitiated)*100);
		}
		return 0;
	}

	getTtsVoiceId(lang){
		if(this.languageTtsVoiceId && this.languageTtsVoiceId[lang]){
			return this.languageTtsVoiceId[lang];
		}
		return this.ttsVoiceId;
	}
	
}
