import Serializable from './serializable';

export class Character extends Serializable {

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	gameId: string;
	collectionPath: string;

	orderNum: number;
	name: string;
	labels: {} = {};
	description: {} = {};

	ttsVoiceId:string;
	languageTtsVoiceId: {} = {};

	pictureUrl: string;
	filePath: string;
	pictureLastUpdate: number;
	//objectPic: {} = {};

	//externalUrl: string;
	//externalUrlByLanguage: {} = {};
	//isExternalUrlInNewWindow: boolean;
	//externalUrlLabel: {} = {};

	//moods
	//{id:1234, pictureMediaId:5678, audioMediaId:9012, ttsVoiceId:'sdfhds', languageTtsVoiceId: {}}
	moods: any[] = [];

	getTtsVoiceId(moodId, lang){
		if(moodId){
			for(let mood of this.moods){
				if(mood.id == moodId){
					if(mood.languageTtsVoiceId && mood.languageTtsVoiceId[lang]){
						return mood.languageTtsVoiceId[lang];
					}
					if(mood.ttsVoiceId){
						return mood.ttsVoiceId;
					}
				}
			}
		}
		if(this.languageTtsVoiceId && this.languageTtsVoiceId[lang]){
			return this.languageTtsVoiceId[lang];
		}
		return this.ttsVoiceId;
	}
}
