import Serializable from './serializable';

export class Voice extends Serializable {

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	templateId: string;
	gameId: string;
	collectionPath: string;

	missionId: string;
	elementId:string;

	lang: string;
	textHash:string;
	ttsVoiceId: string;

	filePath: string;
	fileURL: string;
	fileSize:number;
	lastUpdate: number;
}
