import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';

import { map } from 'rxjs/operators';

import { Voice } from '../classes/index';

// Usage:
// import { VoiceService } from './voice.service';
//
// export class Component {
//   constructor(private audio: AudioService) { }
//   public playTextToSpeech(text:string) {
//     this.audio.playTextToSpeech(text);
//   }
//   public playStreamAudio(text: string) {
//     this.audio.playStreamAudio(text);
//   }
// }

@Injectable({ providedIn: 'root' })
export class VoiceService {
  ELEVEN_LABS_VOICE_ID = "9BWtsMINqrJLrRacOk9x";
  ELEVEN_LABS_API_KEY = environment.ELEVEN_LABS_API_KEY;

  public audio: HTMLAudioElement;

  constructor(private http: HttpClient,
    private afs: AngularFirestore
  ) {
    this.audio = new Audio();
  }

  listVoices(collectionPath, missionId) {
		return this.afs.collection(collectionPath, ref => ref.where('missionId', '==', missionId))
    .snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Voice;
				delete data.collectionPath;
				let obj = new Voice(collectionPath);
				obj.fromJSON(data);
				obj.id = a.payload.doc.id;
				return obj;
			});
		}));
	}

	createVoice(voice) {
		let data = { ...voice };
		console.log('Create voice:'+JSON.stringify(data));
		delete data.collectionPath;
		return this.afs.collection(voice.collectionPath).add(data);
	}

  createVoiceRef(collectionPath){
		return this.afs.collection(collectionPath).doc().ref;
	}

	setVoice(voice) {
		let data = { ...voice };
		console.log('Set voice:'+JSON.stringify(voice));
		delete data.collectionPath;
		return this.afs.collection(voice.collectionPath).doc(voice.id).set(data);
	}

	updateVoice(voice, data){
		data.collectionPath = firebase.firestore.FieldValue.delete();
		return this.afs.collection(voice.collectionPath).doc(`${voice.id}`).update({...data});
	}

	deleteVoice(voice) {
		return this.afs.collection(voice.collectionPath).doc(`${voice.id}`).delete();
	}

  public setAudioSourceAndPlay(source: string): void {
    this.audio.src = source;
    this.audio.play();
  }

  public setAudioAndPlay(data: ArrayBuffer): void {
    const blob = new Blob([data], { type: 'audio/mpeg' });
    const url = URL.createObjectURL(blob);
    this.audio.src = url;
    console.log('Started playing: ' + Date.now());
    this.audio.play();
    console.log('Ended playing: ' + Date.now());
  }

  /*public playTextToSpeech(text: string, voiceId = null) {
    this.getAudio(text, voiceId || this.ELEVEN_LABS_VOICE_ID);
  }*/

  getAudio(text: string, voiceId: string): Promise<any> {
    console.log('getAudio:'+text+';'+voiceId);
    return new Promise((resolve, reject) => {

      const ttsURL = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`;

      const headers = {
        accept: 'audio/mpeg',
        'content-type': 'application/json',
        'xi-api-key': this.ELEVEN_LABS_API_KEY,
      };

      const request = {
        text,
        "model_id": "eleven_multilingual_v2",
        "voice_settings": { //defaults specific to voiceId
          "stability": 0.5,
          "similarity_boost": 0.75,
          "style": 0,
          "use_speaker_boost": true
        }
      };

      console.log('Call made: ' + Date.now());
      this.http
      .post(ttsURL, request, { headers, responseType: 'arraybuffer' })
      .subscribe({
        next: (response: ArrayBuffer) => {
          resolve(response);
        },
        error: (error) => {
          console.error('Error:', error);
          reject();
        }
      });
    });
  }

  playAudio(data: ArrayBuffer) {
    this.setAudioAndPlay(data);
  }

  public async playStreamAudio(text: string, voiceId = null) {
    await this.getStreamAudio(text, voiceId || this.ELEVEN_LABS_VOICE_ID);
  }

  private async getStreamAudio(text: string, voiceId: string) {
    const streamingURL = `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream?optimize_streaming_latency=3`;

    const headers = {
      'content-type': 'application/json',
      'xi-api-key': this.ELEVEN_LABS_API_KEY,
    };

    console.log('Call made: ' + Date.now());
    const request = {
      text,
      "model_id": "eleven_multilingual_v2",
      "voice_settings": { //defaults specific to voiceId
        "stability": 0.5,
        "similarity_boost": 0.75,
        "style": 0,
        "use_speaker_boost": true
      }
    };
    this.http
      .post(streamingURL, request, { headers, responseType: 'arraybuffer' })
      .subscribe({
        next: (response: ArrayBuffer) => {
          this.playAudioStream(response);
        },
        error: (error) => {
          console.error('Error:', error);
        }
      });
  }

  private async playAudioStream(audioData: ArrayBuffer) {
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(audioData);
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);

    source.onended = () => {
      console.log('Ended playing: ' + Date.now());
    };

    let startTime = audioContext.currentTime + 0.1;
    console.log('Started playing: ' + startTime);
    source.start(startTime);
  }
}