import { Component, Input, Output, EventEmitter } from '@angular/core';

import { LanguageService } from '../../_services/index';

import { NbMenuService } from '@nebular/theme';

//import * as Editor from '@ckeditor/ckeditor5-coddy';

import { map, filter } from 'rxjs/operators';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';

import translate from "translate";

import { environment } from './../../../environments/environment';

@Component({
  selector: 'multilang-editor',
  templateUrl: 'multilang-editor.component.html',
  styleUrls: ['./multilang-editor.component.scss']
})

export class MultilangEditorComponent {

  //public Editor = Editor;

  ckeConfig = {
    toolbar: {
      items: ['bold', 'italic', '|', 'bulletedList', 
      'numberedList', '|', 'undo', 'redo', 
      ' classicEditor', 'fontFamily', 'highlight'
      ]
    }
  };

  @Input() view = 'columns';


  constructor(public languageService: LanguageService,
    private menuService: NbMenuService) {
      this.languages = languageService.languagesCodes;

      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === ('multilang-editor'+this.attribute)),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        console.log('onItemClick:'+title);
        if(title == 'Apply selected language(s)'){
          this.applyChanges.emit('selected');
        }
        if(title == 'Apply all languages'){
          this.applyChanges.emit('all');
        }
      });
  }

  applyMenu = [
    { title: 'Apply selected language(s)' },
    { title: 'Apply all languages' }
  ]
  loading = []
  tags = [
    'city',
    'startTimeHour',
    'namesPlayers',
    'duration',
    'missionCount',
    'riddleCount',
    'leaderboardPosition',
    'score',
    'teamName',
  ]
  @Input() defaultLang;
  @Input() tag = false;
  @Input() attribute;
  @Input() languages;
  @Input() model: {};
  @Input() templateModel;
  @Input() editMode = false;
  @Input() htmlMode = true;
  @Input() simple = false;
  @Input() translationButton = true;

  @Input() showApplyChanges = false;
  @Input() readonly;


  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateFromTemplate: EventEmitter<any> = new EventEmitter<any>();

  _changes
  @Input() set changes(changes){
    //console.log('set changes:'+JSON.stringify(changes));
    this._changes = changes;
    if(changes) this.checkForChanges();
  };
  changeCount = 0;
  
  didChange = [];
  checkForChanges(){
    this.didChange = [];
    this.changeCount = 0;
    for (let i = 0; i < this._changes.length; i++) {
      if(this.attribute === this._changes[i].fieldName && this._changes[i].languages){
        for (let j = 0; j < this._changes[i].languages.length; j++) {
          this.didChange[this._changes[i].languages[j]] = true;
          this.changeCount++;
        }
      }
    }
    //console.log('this.didChange:'+JSON.stringify(this.didChange));
  }

  selectTag(tag, lang){
    this.model[lang] = this.model[lang]? this.model[lang] + ' {'+tag+'} ' : ' {'+tag+'} '
  }

  isEmpty(clang){
    if(this.templateModel && (!this.model[clang] || this.model[clang] == '')){
      for(let lang of this.languages){
        if(this.templateModel[lang] && this.templateModel[lang].length>0){
          return true;
        }
      }
    }
    return false;
  }

  editModel() {
    this.edit.emit();
  }

  hasTranslation(){
    let exists = false
    for (const langFrom in this.model) {
      if(this.model[langFrom]) {
        exists = true
        break;
      }
    }
    return exists
  }

  async translateText(model,langTo, i) {
    this.loading[i] = true
    //let defaultLang = this.template?.defaultLang ? this.template?.defaultLang : this.game?.defaultLang
    let translatedText
    translate.engine = "deepl";
    translate.key = environment.deepleKey;
    if(model[this.defaultLang]){
      console.log('translate text '+model[this.defaultLang]+' from '+this.defaultLang+' to '+langTo);
      Promise.race([
        translate(model[this.defaultLang], { from:this.defaultLang, to: langTo }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 4000))
      ])
      .then(res => {
        translatedText = res;
        console.log('res:'+translatedText);
        if(translatedText) this.model[langTo] = translatedText;
        this.loading[i] = false;
      })
      .catch(err => {
          console.log('err',err);
          this.loading[i] = false;
      });
    }else {
      for (const langFrom of this.languages) {
        if(model[langFrom] && model[langFrom].length > 0){
          console.log('translate text '+model[langFrom]+' from '+langFrom+' to '+langTo);
          Promise.race([
            translate(model[langFrom], { from:langFrom, to: langTo }),
            new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 4000))
          ])
          .then(res => {
            translatedText = res;
            console.log('res:'+translatedText);
            if(translatedText) this.model[langTo] = translatedText;
            this.loading[i] = false;
          })
          .catch(err => {
              console.log('err',err);
              this.loading[i] = false;
          });
          break;
        }
      }
    }
  }

  updateFieldFromTemplate(){
    this.updateFromTemplate.emit();
  }
}
